import React from 'react';
import styled, { css } from 'styled-components';

import { Col, Container, Row } from 'components/Layout/Grid';

import { androidDocsLink, iOSDocsLink } from 'helper/constants/constants';
import { smallTablet, largeTablet, desktop } from 'helper/constants/mediaRules';
import { COLOR_CTA_HOVER_BG, JAVA_GREEN, JAVA_HOVER, MONA_LISA_PINK } from 'helper/constants/styles';
import strings from 'helper/localization/localization';

import AndroidIcon from 'images/android_icon_larger.svg';
import IOSIcon from 'images/ios_icon_larger.svg';

import SEO from '../components/Seo';

const PageContainer = styled(Container)`
  margin: 2rem auto 4rem;
  flex-direction: column;
  ${smallTablet(css`
    margin: 3rem auto 4rem;
  `)}

  ${largeTablet(css`
    margin: 4rem auto 0rem;
  `)}

  ${desktop(css`
    margin: 6rem auto 0rem;
  `)}
`;

const DocsTitle = styled.h1`
  text-transform: capitalize;
  font-size: 3em;
`;

const IconRow = styled(Row)`
  margin: 2rem auto;
  justify-content: center;
  ${largeTablet(css`
    justify-content: flex-start;
  `)}
`;

const PlatformLink = styled(Col)`
  text-align: center;
`;

const LinkStyle = css`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background-color: ${props => (props.ios ? MONA_LISA_PINK : JAVA_GREEN)};
  transition: 0.4s ease;
`;

const IosContainer = styled.a`
  ${LinkStyle}
  margin-bottom: 0.8rem;
  &:hover {
    background-color: ${COLOR_CTA_HOVER_BG};
  }
`;

const IconImg = styled.img`
  max-width: 28%;
`;

const AndroidContainer = styled.a`
  ${LinkStyle}
  margin-bottom: 0.8rem;
  &:hover {
    background-color: ${JAVA_HOVER};
  }
`;

const Documentation = () => (
  <>
    <SEO title="Documentation" />
    <PageContainer id="qa-documentation-page" width={[1, 1, 1, 1]}>
      <DocsTitle>{strings.supportDocumentation}</DocsTitle>
      <h3>{strings.docsPageBody}</h3>
      <IconRow>
        <PlatformLink>
          <IosContainer
            rel="noopener noreferrer"
            target="_blank"
            id="ios-icon"
            href={iOSDocsLink}
            ios
          >
            <IconImg src={IOSIcon} alt="" />
          </IosContainer>
          <label htmlFor="ios-icon">{strings.iosDocs}</label>
        </PlatformLink>
        <PlatformLink>
          <AndroidContainer
            rel="noopener noreferrer"
            target="_blank"
            id="android-icon"
            href={androidDocsLink}
          >
            <IconImg src={AndroidIcon} alt="" />
          </AndroidContainer>
          <label htmlFor="android-icon">{strings.androidDocs}</label>
        </PlatformLink>
      </IconRow>
    </PageContainer>
  </>
);

export default Documentation;
